import MTEHeader from 'components/MTEHeader/MTEHeader';
import MTEReportInputBody from 'pages/MTEReportInput/MTEReportInputBody';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { MTEMembershipLayout } from 'styles/ReportStyles/MTEMembershipStyles';
import MTEMembershipInformationBody from './MTEMembershipInformationBody';
import ReportListPage from 'pages/Report/ReportListPage';

const MTEMembershipInformation = () => {
    const { pathname } = useLocation();
    const reportInputRef = useRef<{ onReset: () => void }>(null);
    const userInformationRef = useRef<{ onReset: () => void }>(null);

    const handleReset = () => {
        if (pathname.includes('user-information') && userInformationRef.current) {
            userInformationRef.current.onReset();
        }

        if (pathname.includes('report-input') && reportInputRef.current) {
            reportInputRef.current.onReset();
        }
    };
    return (
        <MTEMembershipLayout>
            {(pathname.includes('user-information') || pathname.includes('report-input')) && <MTEHeader onReset={handleReset} />}
            {pathname.includes('user-information') && <MTEMembershipInformationBody ref={userInformationRef} />}
            {pathname.includes('report-input') && <MTEReportInputBody ref={reportInputRef} />}
            {pathname.includes('reportList') && <ReportListPage />}
        </MTEMembershipLayout>
    );
};

export default MTEMembershipInformation;
