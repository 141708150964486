export type scoreNumber = '0' | '1' | '2' | '3';

export const scoreNumberValues: scoreNumber[] = ['3', '2', '1', '0'];

export type scoreType = 'score' | 'boolean' | 'sec';

export type assessmentInputType = string | scoreNumber | boolean | undefined;

export type BDIAssessmentType = {
    score: string;
    name: string;
};

export const BDIAssessmentValues: BDIAssessmentType[] = [
    {
        name: '전혀 숨차지 않음',
        score: '0',
    },
    {
        name: '숨이 찰듯말듯 함',
        score: '0.5',
    },
    {
        name: '아주 약간 숨이 참',
        score: '1',
    },
    {
        name: '약간 숨이 참',
        score: '2',
    },
    {
        name: '중등도로 숨이 참',
        score: '3',
    },
    {
        name: '약간 심하게 숨이 참',
        score: '4',
    },
    {
        name: '심하게 숨이 참',
        score: '5',
    },
    {
        name: '',
        score: '6',
    },
    {
        name: '아주 심하게 숨이 참',
        score: '7',
    },
    {
        name: '',
        score: '8',
    },
    {
        name: '아주 아주 심하게 숨이 참',
        score: '9',
    },
    {
        name: '극도로 심하게 숨이 참',
        score: '10',
    },
];

export interface AssessmentInputProps {
    score: assessmentInputType;
    scoreType: scoreType;
    name: string | undefined;
    unit: string[] | string | undefined;
    enable?: boolean | undefined;
    children?: AssessmentInputProps[];
}

export class AssessmentInputModel {
    constructor(public score: string | undefined, public readonly name: string, public readonly unit?: string) {
        this.unit = unit;
        this.name = name;
        this.score = score;
    }

    setScore(score: string | undefined) {
        this.score = score;
    }
}

export class AssessmentSubjectModel {
    constructor(public readonly subjectName: string, public readonly subjectDescription: string, public assessmentInputList: AssessmentInputModel[], public onChange: ((value: string) => void)[]) {
        this.subjectName = subjectName;
        this.subjectDescription = subjectDescription;
        this.assessmentInputList = assessmentInputList;
        this.onChange = onChange;
    }
}

export type SPPBAssessmentEventType = 'isGeneralPosture' | 'isNonLinearPosture' | 'isLinearPosture' | 'isWalk' | 'walkTime' | 'isStand' | 'standTime';

export type SPPBAssessmentValueType = string | boolean | undefined;

export interface SPPBAssessmentInputProps {
    isGeneralPosture: boolean | undefined;
    isNonLinearPosture: boolean | undefined;
    isLinearPosture: string | undefined;
    isWalk: boolean | undefined;
    walkTime: string | undefined;
    isStand: boolean | undefined;
    standTime: string | undefined;
    isLinearPostureDisabled: boolean;
    isWalkDisabled: boolean;
    isStandDisabled: boolean;
    isNoneLinearPostureDisabled: boolean;
    onEvent: (key: SPPBAssessmentEventType, value: SPPBAssessmentValueType) => void;
}

export interface TUGAssessmentInputProps {
    tugTime: string | undefined;
    onChangeTugTime: (value: string | undefined) => void;
}

export interface MobilityAssessmentInputProps {
    assessmentInputList: AssessmentSubjectModel[];
    FRT: string | undefined;
    onChangeInput: (value: string | undefined) => void;
}

export interface MuscularEnduranceAssessmentInputProps {
    sitStand: string | undefined;
    core: string | undefined;
    upper: string | undefined;
    onEvent: (key: MuscleInputEventType, value: string | undefined) => void;
}

export type RespiratoryAssessmentEventType = 'walkDistance' | 'vo2Max' | 'BDIBefore' | 'BDIAfter' | 'hrBefore' | 'hrAfter' | 'spo2Before' | 'spo2After' | 'menualVo2Max';
export type MuscleInputEventType = 'upper' | 'core' | 'sitStand';

export interface RespiratoryAssessmentInputProps {
    walkDistance: string;
    vo2Max: string;
    BDIBefore: string;
    BDIAfter: string;
    hrBefore: string;
    hrAfter: string;
    spo2Before: string;
    spo2After: string;
    menualVo2Max: string;
    errorMsgs: { [key in RespiratoryAssessmentEventType]?: string };
    disabledWalkDistance: boolean;
    disabledMenualVo2Max: boolean;
    onEvent: (key: RespiratoryAssessmentEventType, value: string) => void;
    onBlur: (key: RespiratoryAssessmentEventType, value: string) => void;
}
