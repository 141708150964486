import { pagePaths } from 'constants/path';
import { getReportResponseType } from 'models/MTE/reportApiTypes';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useLoadingStore } from 'store/useLoadingStore';

export const ReportView = () => {
    const { setLoading } = useLoadingStore();
    const [report, setReport] = useState<getReportResponseType | null>(null);

    const windowListener = (event: MessageEvent<any>) => {
        /// Todo : 필터링 되는 오리진 변경 하기
        if (
            event.origin !== 'http://192.168.10.178:56233' &&
            event.origin !== 'http://localhost:56599' &&
            event.origin !== 'http://mtem.tlchealthcare.co.kr' &&
            event.origin !== 'http://192.168.10.178:54841'
        )
            return;

        /// 윈도우에서 넘어온 데이터 파싱 및 타입 매칭
        const typeMatcher = (type: getReportResponseType | null): type is getReportResponseType => {
            return (type as getReportResponseType) !== undefined;
        };

        const response = JSON.parse(event.data);

        let reportData = typeMatcher(response) ? response : undefined;

        /// 데이터 파싱 실패 시 에러 메세지 전송
        if (reportData === undefined || reportData === null) {
            window.opener.postMessage(
                JSON.stringify({
                    result: 'fail',
                }),
                '*'
            );
            return;
        }

        /// 데이터 전송
        setLoading(false);
        setReport(reportData);
    };

    useEffect(() => {
        setLoading(true);
        window.addEventListener('message', windowListener);
        window.opener.postMessage(JSON.stringify({ result: 'ready' }), '*');
        return () => {
            window.removeEventListener('message', windowListener);
        };
    }, []);

    /// 리포트 파싱 성공 시 메세지 전송
    useEffect(() => {
        if (!report) return;
    }, [report]);

    if (report) return <Navigate to={pagePaths.report} state={{ response: report, type: 'mobile' }} replace />;

    return <></>;
};
