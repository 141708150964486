import { Assessment } from 'components/Common/Assessment';
import { MuscularEnduranceAssessmentInputProps } from 'models/MTE/ReportInput/assessmentInputTypes';
import React from 'react';
import {
    AssessmentName,
    AsssessmentInputWrapper,
    ContainerBodyWrapper,
    ContainerHeaderWrapper,
    ContainerLayout,
    InnerDivider,
    SubTitle,
    Title,
    WidthSpacer,
} from 'styles/ReportStyles/MTEReportInputStyles';
import { NUMBER_3_REGEX, NUMBER_4_DIGIT_1_REGEX } from 'utils/regex';

export const MuscularEnduranceAssessment = React.memo(({ onEvent, sitStand, core, upper }: MuscularEnduranceAssessmentInputProps) => {
    return (
        <ContainerLayout>
            <ContainerHeaderWrapper>
                <Title>4. 근지구력 평가</Title>
                <WidthSpacer $width={18} />
                <SubTitle>30초 기준 근지구력 검사</SubTitle>
            </ContainerHeaderWrapper>
            <InnerDivider />
            <ContainerBodyWrapper>
                <AsssessmentInputWrapper $width={780}>
                    <AssessmentName>1. 상체 근지구력 (어깨 밀어올리기)</AssessmentName>
                    <Assessment.Input
                        value={upper}
                        unit='회'
                        onChange={(e) => {
                            NUMBER_3_REGEX.test(e.currentTarget.value) && onEvent('upper', e.currentTarget.value);
                        }}
                    />
                </AsssessmentInputWrapper>
                <AsssessmentInputWrapper $width={780}>
                    <AssessmentName>2. 몸통 근지구력(몸통 60도 굴곡 유지하기)</AssessmentName>
                    <Assessment.Input
                        value={core}
                        unit='초'
                        onChange={(e) => {
                            NUMBER_4_DIGIT_1_REGEX.test(e.currentTarget.value) && onEvent('core', e.currentTarget.value);
                        }}
                    />
                </AsssessmentInputWrapper>
                <AsssessmentInputWrapper $width={780}>
                    <AssessmentName>3. 하지 근지구력 (30초 의자 앉아 일어서기)</AssessmentName>
                    <Assessment.Input
                        value={sitStand}
                        unit='회'
                        onChange={(e) => {
                            NUMBER_3_REGEX.test(e.currentTarget.value) && onEvent('sitStand', e.currentTarget.value);
                        }}
                    />
                </AsssessmentInputWrapper>
            </ContainerBodyWrapper>
        </ContainerLayout>
    );
});
