import App from 'App';
import MTEMembershipInformation from 'pages/MTEMemberInformation/MTEMembershipInformation';
import MTEMembershipInformationBody from 'pages/MTEMemberInformation/MTEMembershipInformationBody';
import MTEReportInputBody from 'pages/MTEReportInput/MTEReportInputBody';
import ReportListPage from 'pages/Report/ReportListPage';
import { ReportView } from 'pages/ReportView/ReportView';
import { lazy } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import ScrollToTop from './components/Common/ScrollToTop';
import { pagePaths } from './constants/path';
import ErrorPage from './pages/ErrorPage/ErrorPage';

const ReportAllPage = lazy(() => import('pages/Report/ReportAllPage'));

const router = createBrowserRouter([
    //Todo: 테스트 이후 제거하기
    {
        path: '/',
        element: <Navigate to={pagePaths.userInformation} replace />,
    },
    {
        path: '/MTE',
        element: (
            <>
                <ScrollToTop />
                <App />
            </>
        ),
        errorElement: <ErrorPage />,
        children: [
            {
                path: pagePaths.report,
                element: <ReportAllPage />,
            },
            {
                path: pagePaths.reportView,
                element: <ReportView />,
            },
            /// 테스트 전용 페이지
            // {
            //     path: '/MTE/testPage',
            //     element: <TestPage />,
            // },
            {
                path: '',
                element: <MTEMembershipInformation />,
                children: [
                    { path: pagePaths.userInformation, element: <MTEMembershipInformationBody /> },
                    { path: pagePaths.reportInput, element: <MTEReportInputBody /> },
                    { path: pagePaths.report, element: <ReportAllPage /> },
                    { path: pagePaths.reportList, element: <ReportListPage /> },
                ],
            },
        ],
    },
]);

export default router;
